@import 'variables';

@import '~bootstrap/scss/bootstrap';

div#root {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 100vh;
}
main {
  flex-grow: 1;
}

/* ===== Navbar ===== */
.navbar-brand > img {
  -webkit-filter: drop-shadow(0px 0px 3px #fff);
  filter: drop-shadow(0px 0px 3px #fff);
}
.navbar-nav .nav-link.active {
  border-bottom: solid $navbar-dark-active-color 1px;
}

/* ===== Typography ===== */
.text-light .text-muted {
  color: $gray-400 !important;
}
h2 > small {
  font-size: 50%;
}
.badge.badge-dark {
  background-color: #5f5f5f;
}

/* ===== Tooltips ===== */
/* Thanks to https://www.w3schools.com/css/css_tooltip.asp */
.tooltip-clv {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip-clv .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  right: 2rem;
  max-width: 80vw;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip-clv:hover .tooltiptext {
  visibility: visible;
}

/* ===== Album List ===== */
.album-thumb {
  max-height: 3rem;
}

/* ===== Album Detail ===== */
.album-label {
  font-weight: bold;
}
